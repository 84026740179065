import { request } from 'network/request'

export function postAcceptanceData(parameter) {
  return request({
    url: '/acceptance',
    method: 'post',
    data: parameter
  })
}

export function putAcceptanceData(parameter) {
  return request({
    url: '/acceptance',
    method: 'put',
    data: parameter
  })
}

export function getAcceptanceDataAll(parameter) {
  return request({
    url: '/acceptance/all/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function getAcceptanceDataOne(parameter) {
  return request({
    url: '/acceptance/one/' + parameter.acceptance_code,
    method: 'get',
    data: parameter
  })
}

export function getAcceptancePdf(parameter) {
  return request({
    url: '/acceptance/pdf/' + parameter.acceptance_code,
    method: 'get',
    data: parameter
  })
}
