import { request } from 'network/request'

export function postDeliveryData(parameter) {
  return request({
    url: '/delivery',
    method: 'post',
    data: parameter
  })
}

export function putDeliveryData(parameter) {
  return request({
    url: '/delivery',
    method: 'put',
    data: parameter
  })
}

export function getDeliveryDataAll(parameter) {
  return request({
    url: '/delivery/all/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function getDeliveryDataOne(parameter) {
  return request({
    url: '/delivery/one/' + parameter.delivery_code,
    method: 'get',
    data: parameter
  })
}

export function getDeliveryPdf(parameter) {
  return request({
    url: '/delivery/pdf/' + parameter.delivery_code,
    method: 'get',
    data: parameter
  })
}
